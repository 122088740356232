import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody } from 'reactstrap'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article from '../../components/parent/article3'
import UsefulTools from '../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../components/parent/curiosity'

const IndexPage = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article-header3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Tysiak"
        description="Grzegorz Kasdepke o tym, jak bardzo dzieci potrafią być niedyskretne, gdy rozmowa schodzi na finanse! A także co ma wspólnego edukacja ekonomiczna ze zwykłą osiedlową biblioteką, ile musiał się nauczyć, żeby napisać książkę „Zaskórniaki i inne dziwadła z krainy portfela”, i o trudnym losie pisarza na spotkaniu autorskim."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={<>Tysiak</>}
        desc={
          <p>
            Grzegorz Kasdepke o{'\u00A0'}tym, jak bardzo dzieci
            <br />
            potrafią być niedyskretne, gdy rozmowa schodzi
            <br />
            na finanse! A{'\u00A0'}także co{'\u00A0'}ma wspólnego edukacja
            <br />
            ekonomiczna ze{'\u00A0'}zwykłą osiedlową biblioteką, ile
            <br />
            musiał się nauczyć, żeby napisać książkę
            <br />
            „Zaskórniaki i{'\u00A0'}inne dziwadła z{'\u00A0'}krainy portfela”,
            <br />i o{'\u00A0'}trudnym losie pisarza na{'\u00A0'}spotkaniu
            autorskim.
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article />
      <UsefulTools
        items={[
          {
            title: 'Ciekawostki',
            subtitle:
              'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
            image: data.Tool3,
            onClick: toggleModal,
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Kolorowanka',
            image: data.Tool2,
            link: '/parent/Kolorowanka%201.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Bankosłówka',
            image: data.Tool1,
            link: '/parent/Bankoslowka.pdf',
          },
        ]}
      />

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default IndexPage
