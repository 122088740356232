import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article3/person3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(relativePath: { eq: "parent/article3/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 728) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Grzegorz Kasdepke - pisarz"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Grzegorz Kasdepke</span>{' '}
                <span className="personPosition">pisarz</span>
                <br />
                <ArticleAboutModal>
                  Od ponad czterdziestu lat jestem zapalonym czytelnikiem, od
                  {'\u00A0'}
                  prawie dwudziestu &ndash; zawodowym pisarzem, od{'\u00A0'}
                  trzech &ndash; Kawalerem Orderu Uśmiechu. Dwie spośród
                  napisanych przeze mnie książek przybliżają dzieciom pojęcia
                  ekonomiczne: „Pestka, drops, cukierek” oraz „Zaskórniaki i
                  {'\u00A0'}inne dziwadła z{'\u00A0'}krainy portfela” &ndash;
                  obydwie zdobyły nagrody, ale tylko jedna Nagrodę Ekonomistów
                  BETA 2015 („Zaskórniaki…”). Jak widać, nie unikam liczebników
                  ani też rozmów o{'\u00A0'}pieniądzach &ndash; nawet z
                  {'\u00A0'}dziećmi, które podczas spotkań autorskich pytają
                  najczęściej o{'\u00A0'}jedno: „Ile pan zarabia?!”.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Od{'\u00A0'}ponad czterdziestu lat jestem zapalonym
                czytelnikiem, od{'\u00A0'}prawie dwudziestu - zawodowym
                pisarzem, od{'\u00A0'}trzech - Kawalerem Orderu Uśmiechu. Dwie
                spośród napisanych przeze mnie książek przybliżają dzieciom
                pojęcia ekonomiczne: „Pestka, drops, cukierek” oraz „Zaskórniaki
                i{'\u00A0'}inne dziwadła z{'\u00A0'}krainy portfela” - obydwie
                zdobyły nagrody, ale tylko jedna Nagrodę Ekonomistów BETA 2015
                („Zaskórniaki…”). Jak widać nie unikam liczebników, ani też
                rozmów o{'\u00A0'}pieniądzach - nawet z{'\u00A0'}dziećmi, które
                podczas spotkań autorskich pytają najczęściej o{'\u00A0'}jedno:
                „Ile pan zarabia?!”
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row>
            <Col xs={12} className="marginTop30">
              <p>
                Wielu rodziców byłoby zaskoczonych, wiedząc, o{'\u00A0'}co
                pytają ich dzieci, gdy wraz z{'\u00A0'}całą klasą przychodzą na
                {'\u00A0'}spotkania autorskie.
                <br />– Jak to{'\u00A0'}jest być starym człowiekiem? – słyszę
                często, stojąc w{'\u00A0'}wypełnionej smarkatą publicznością
                bibliotece.
                <br />– Znał pan Andersena?
                <br />– Był pan kiedyś przystojny?
                <br />
                Bo też los autora książek dla dzieci i{'\u00A0'}młodzieży nie
                jest, proszę Państwa, usłany różami, a{'\u00A0'}już na{'\u00A0'}
                pewno nie wyłącznie ich płatkami – za{'\u00A0'}to pozwala
                zachować do{'\u00A0'}siebie dystans. No{'\u00A0'}i{'\u00A0'}daje
                możliwość zapoznania się ze{'\u00A0'}sprawami, które dzieci
                naprawdę intrygują.
                <br />– Jaki ma{'\u00A0'}pan telefon? – pytają ośmiolatki.
                <br />– A{'\u00A0'}samochód?
                <br />– A{'\u00A0'}co by{'\u00A0'}pan wolał: żonę czy
                lamborghini? – zaciekawił się kiedyś chłopiec w{'\u00A0'}
                popegeerowskiej miejscowości.
                <br />
                Najczęściej jednak dzieci pytają wprost:
                <br />– Ile pan zarabia?!
                <br />
                Tak, tak, proszę Państwa, pieniądze to{'\u00A0'}jest to, co
                {'\u00A0'}w{'\u00A0'}znacznym stopniu kręci naszych
                milusińskich. Ale nie dziwota; dzieci żyją dokładnie w{'\u00A0'}
                tym samym świecie co{'\u00A0'}my, dorośli, często oglądają z
                {'\u00A0'}nami te{'\u00A0'}same seriale, słyszą nasze dyskusje,
                chodzą z{'\u00A0'}nami na{'\u00A0'}zakupy… W{'\u00A0'}wielu
                domach rozmowy krążą wokół zarobków, wydatków, kredytów i
                {'\u00A0'}utyskiwań na{'\u00A0'}drożyznę. Zazdrościmy sąsiadom
                większego mieszkania, marzymy o{'\u00A0'}podróżach (w które
                ruszylibyśmy, gdyby na{'\u00A0'}nasze konto wpłynęła większa
                sumka), wzdychamy na{'\u00A0'}widok absurdalnie drogiego ciucha
                celebrytki z{'\u00A0'}telewizji. A{'\u00A0'}dzieci siedzą gdzieś
                z{'\u00A0'}boku i{'\u00A0'}pozornie są{'\u00A0'}całkowicie
                pochłonięte zabawą – choćby i{'\u00A0'}rysowaniem w{'\u00A0'}
                zeszycie – w{'\u00A0'}rzeczywistości jednak biernie nasiąkają
                naszymi pragnieniami, lękami, a{'\u00A0'}często i{'\u00A0'}
                frustracjami.
                <br />– No{'\u00A0'}to ile pan zarabia, tak miesięcznie? –
                słyszę w{'\u00A0'}bibliotekach, domach kultury i{'\u00A0'}
                szkołach całej Polski.
                <br />
                Pierwszym zdziwieniem jest to, że{'\u00A0'}pisarz rzadko kiedy
                zarabia miesiąc w{'\u00A0'}miesiąc. O{'\u00A0'}regularnych
                zarobkach w{'\u00A0'}tej branży raczej nie ma{'\u00A0'}co marzyć
                – pieniądze wpływają na{'\u00A0'}pisarskie konto albo kilka razy
                w{'\u00A0'}tygodniu, albo raz na{'\u00A0'}kwartał, a{'\u00A0'}
                czasami jeszcze rzadziej (oby obficie!).
                <br />
                Drugim zdziwieniem jest to, że{'\u00A0'}z{'\u00A0'}pisania w
                {'\u00A0'}ogóle można się utrzymać.
                <br />– No{'\u00A0'}ale ile, ile? – nie ustępują
                trzecioklasiści.
                <br />
                (W takich chwilach nawet przycupnięci na{'\u00A0'}końcu sali
                nauczyciele milkną – choć zazwyczaj pytlują półgłosem przez całe
                spotkanie).
                <br />– Zarabiam tyle – powiedziałem kiedyś – ile powinien
                zarabiać człowiek mieszkający w{'\u00A0'}Europie.
                <br />
                Siedzący w{'\u00A0'}pierwszym rzędzie chłopiec trącił łokciem
                kolegę i{'\u00A0'}usłyszałem jego donośny szept:
                <br />– Z{'\u00A0'}tysiaka ma!
              </p>
            </Col>
            <Col xs={12}>
              <div className="imgWrapper imgCenter">
                <Img
                  fluid={data.Article1Image1.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col xs={12} className="marginTop30">
              <p>
                Od{'\u00A0'}tamtej pory, zamiast głupio się krygować,
                wykorzystuję pytania o{'\u00A0'}zarobki do{'\u00A0'}
                przeprowadzenia minilekcji o{'\u00A0'}ekonomii – czasami
                pierwszej w{'\u00A0'}życiu słuchających mnie dzieci. Mówię o
                {'\u00A0'}produkcie, jakim jest książka, opowiadam, ile osób
                pracuje przy jej powstaniu, tłumaczę, jaką drogę przebywa, zanim
                trafi na{'\u00A0'}księgarskie półki (poczynając od{'\u00A0'}
                autora tekstu, poprzez ilustratora, wydawcę, drukarza,
                hurtownika, a{'\u00A0'}kończąc na{'\u00A0'}księgarzu), dalej
                wyjaśniam, jak są{'\u00A0'}dzielone zyski między wszystkie osoby
                zaangażowane w{'\u00A0'}tworzenie książki… i{'\u00A0'}tu
                następuje trzecie zdziwienie!
                <br />– Jak to? – wołają dzieciaki. – To{'\u00A0'}pan nie
                dostaje wszystkiego?!
                <br />
                Bo dotąd były pewne, że{'\u00A0'}skoro na{'\u00A0'}tylnej
                okładce książki widnieje cena w{'\u00A0'}wysokości, dajmy na
                {'\u00A0'}to, dwudziestu złotych, to{'\u00A0'}cała ta{'\u00A0'}
                kwota trafia do{'\u00A0'}mojej kieszeni.
                <br />– Mniej więcej dziesięć procent – tłumaczę. – Czyli dwa
                złote.
                <br />– To{'\u00A0'}bardzo mało – litują się nade mną uczniowie.
                <br />
                Jest więcej okazja do{'\u00A0'}opowiedzenia o{'\u00A0'}
                bestsellerze i{'\u00A0'}o klapie wydawniczej. A{'\u00A0'}że mam
                szczęście być autorem co{'\u00A0'}najmniej kilku bestsellerów, w
                {'\u00A0'}tym lektur szkolnych („Detektyw Pozytywka” jest tego
                najlepszym przykładem), oraz jednej klapy wydawniczej (mowa o
                {'\u00A0'}mojej ukochanej książce pod tytułem „Kiedy byłem
                mały”), to{'\u00A0'}mogę dzielić się doświadczeniami znanymi z
                {'\u00A0'}autopsji, co{'\u00A0'}zazwyczaj uspokaja najbardziej
                zaniepokojonych losem biednego autora. Ostatecznym argumentem
                bywa moja pyzata buzia.
                <br />
                Po którymś ze{'\u00A0'}spotkań opowiadałem znajomemu wydawcy o
                {'\u00A0'}pytaniach zadawanych przez dzieci – a{'\u00A0'}ten aż
                {'\u00A0'}podskoczył!
                <br />– Napisz o{'\u00A0'}tym! – zawołał.
                <br />– O{'\u00A0'}spotkaniach?
                <br />– O{'\u00A0'}ekonomii, dla dzieci! – zapalił się. – Ale
                tak, żeby i{'\u00A0'}rodzice mogli się czegoś dowiedzieć!
                <br />– Nie ma{'\u00A0'}mowy – odpowiedziałem stanowczo.
                <br />
                Bo też nie wydawało mi{'\u00A0'}się możliwe napisanie ciekawej
                książki o{'\u00A0'}ekonomii, zwłaszcza dla dzieci.
                <br />
                Wydawca jednak nie ustępował, dzwonił co{'\u00A0'}kilka tygodni,
                ponawiając propozycję, a{'\u00A0'}potem i{'\u00A0'}podbijając
                honorarium. Wreszcie wziął mnie pod włos argumentem: „Któż jak
                nie ty?!”. Zacząłem więc przygotowywać się do{'\u00A0'}pisania.
                Rozmawiałem z{'\u00A0'}finansistami, przestałem omijać w
                {'\u00A0'}gazetach strony z{'\u00A0'}nagłówkiem „Gospodarka”,
                sięgnąłem po{'\u00A0'}kilka książek popularyzujących ekonomiczną
                wiedzę. Zaprosiłem też do{'\u00A0'}współpracy Ryszarda Petru,
                który wtedy jeszcze nie zdradzał ambicji politycznych – miał
                czuwać, abym nie popełnił żadnego zawstydzającego błędu, miał
                też gdzieniegdzie dopisać krótkie komentarze. Za{'\u00A0'}
                ilustrowanie książki miał odpowiadać genialny Daniel de
                {'\u00A0'}Latour.
                <br />
                „Zaskórniaki i{'\u00A0'}inne dziwadła z{'\u00A0'}krainy
                portfela” ukazały się w{'\u00A0'}2015 roku, od{'\u00A0'}razu też
                zdobyły prestiżową nagrodę finansistów BETA – po{'\u00A0'}raz
                pierwszy uhonorowano w{'\u00A0'}ten sposób książkę dla dzieci.
                Dla dzieci, ale i{'\u00A0'}dla dorosłych – zaznaczę raz jeszcze.
                Jeżeli szukają Państwo lektur uzupełniających domową edukację
                ekonomiczną, z{'\u00A0'}czystym sumieniem polecam „Zaskórniaki…”
                właśnie. I{'\u00A0'}wcale nie namawiam teraz do{'\u00A0'}kupna
                tej książki, jest niemal w{'\u00A0'}każdej bibliotece publicznej
                – możną ją{'\u00A0'}wypożyczyć za{'\u00A0'}darmo. Autor swego
                tysiaka już dostał…
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
